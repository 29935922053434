import React from "react";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    <div
      className="not-found"
      style={{ display: "grid", height: "100vh", justifyContent: "center" }}
    >
      <SEO title="404: Not found" />
      <div style={{ margin: "auto" }}>
        <h1>404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </>
);

export default NotFoundPage;
